import React from 'react';
import SgLayout from '~layouts/sg-layout';
import spacing from "~1-core/_spacing.scss";

const SgSpacing = () => {
    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Spacing units</h1>
                    <div className="sg-eg-spacing">
                        {Object.keys(spacing).map(function(key, index) {
                            return <p key={index}><span className="spacing-var">${key}:</span> {parseInt(spacing[key])}px</p>;
                        })}
                    </div>
                </div>
            </section>
        </SgLayout>
    );
};

export default SgSpacing;
